
<script setup>
    import { computed } from "vue";

    import colors from "mobile.colors.js";

    const props = defineProps({
        variant: {
            type: String,
            default: "contained",
        },
        size: {
            type: String,
            default: "md",
        },
        color: {
            type: String,
            default: "muted",
        },
        uppercase: {
        },
        modelValue: {},
        onClick: null,
        onClose: null,
    });
    const emit = defineEmits(["update:modelValue", "click", "close"]);

    const style = computed(() => {
        const style = {};
        if (props.modelValue != null) {
            /* new version with model value */
            if (props.modelValue) {
                return {
                    color: colors[props.color].color,
                    backgroundColor: colors[props.color].background,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "none",
                }
            } else {
                return {
                    color: "black",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: colors[props.color].background,
                    background: "",
                }
            }
        }
        if (props.variant === "contained") {
            style.color = colors[props.color].color;
            style.backgroundColor = colors[props.color].background;
            style.borderWidth = "1px";
            style.borderStyle = "solid";
            style.borderColor = "none";
        } else if (props.variant === "outlined") {
            //style.color = colors[props.color].background;
            style.color = "black";
            style.borderWidth = "1px";
            style.borderStyle = "solid";
            style.borderColor = colors[props.color].background;
            style.background = "";
        }
        return style;
    });

    function onClick(e) {
        emit("click", e);
        emit("update:modelValue", !props.modelValue);
    }

</script>

<template>
    <div
        class="afm-pill"
        :class="{ 'uppercase-spaced': props.uppercase != null, ['afm-pill-' + props.size]: true }"
        :style="style"
        @click="onClick"
    >
        <div class="flex-grow-1 d-flex align-items-center gap-1 text-truncate">
            <template v-if="modelValue">
                <div>
                    <i class="bi bi-check" style="font-size: 1.25em;" />
                </div>
            </template>
            <div class="text-truncate" style="padding: 0.25rem 0rem;">
                <slot />
            </div>
        </div>
        <template v-if="props.onClose">
            <a
                class="flex-shrink-0 d-flex justify-content-center align-items-center"
                style="height: 1rem; aspect-ratio: 1; border-radius: 999px; text-decoration: none;"
                @click.stop="emit('close', $event)"
            >
                <i class="far fa-times" style="position: relative; top: 1px; font-size: 0.875em;" />
            </a>
        </template>
    </div>
</template>

<style scoped>
    .afm-pill {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        font-size: 0.7em;
        /* background-color: rgb(90%, 90%, 90%); */
        padding: 0rem 0.75rem;
        border-radius: 999px;
        box-sizing: border-box;
        transition: all 100ms ease-in-out;
    }

    .afm-pill-sm {
        font-size: 0.7em;
    }

    .afm-pill-md {
        font-size: 0.9em;
    }

    .afm-pill-lg {
        font-size: 1.1em;
    }
</style>
